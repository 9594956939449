import { PARTY_WALL_ACT } from '@/values/fileSlugs';

export default {
  partyWallAct: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: PARTY_WALL_ACT, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'partyWallActNotice',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: 'Has any notice been received under the Party Wall Act 1996 in respect of any shared/party boundaries?',
      documentText: 'Please supply a copy, and give details of any works carried out or agreed',
    },
  },
};
