<script>
import DisplaySection from '@/components/supportingDocumentsList/DisplaySection';
import aboutYourRemortgage from '@/functions/supportingDocuments/aboutYourRemortgage';
import transferOfEquity from '@/functions/supportingDocuments/transferOfEquity';
import { mapState } from 'vuex';

const formSectionInfo = {
  aboutYourRemortgage: 'Client Questionnaire - About Your Remortgage',
  transferOfEquity: 'Client Questionnaire - Transfer Of Equity',
};

export default {
  name: 'DisplayList',
  components: { DisplaySection },
  props: ['entityType', 'entityId'],
  data() {
    return {
      aboutYourRemortgage,
      transferOfEquity,
      formSectionInfo,
    };
  },
  computed: {
    ...mapState({
      s_remortgage: (state) => state.remortgage,
      s_additionalToePersons: (state) => state.persons.additionalToePersons,
      s_removedToePersons: (state) => state.persons.removedToePersons,
    }),
    v_remortgage: {
      get() {
        return this.s_remortgage;
      },
    },
  },
  methods: {
    navigateToCaseView() {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <h2>Check and Upload Supporting Documents</h2>
          </div>

          <div class="col-md-3">
            <button class="btn btn-info border-info float-right" @click="navigateToCaseView">Return to Case</button>
          </div>
        </div>
      </div>

      <div class="col-md-12 my-3">
        <h5>
          Based on your answers to the client questionnaire and information forms, these are the supporting documents
          you have indicated are applicable and available to attach to your case. Making these documents available at
          this stage will ensure all parties have the information they need to progress as swiftly as possible.
          <b
            >This list serves only as a reminder of your answers and to help collate the necessary documentation and
            will not prevent your case from progressing if you are unable to provide certain documents.</b
          >
        </h5>
      </div>

      <!-- display document list section -->
      <div class="col-md-12">
        <!-- display headers -->
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h5 class="d-flex justify-content-center justify-content-md-start text-uppercase">
              Required Document Name:
            </h5>
          </div>
          <div class="d-none d-md-block col-md-3">
            <h5 class="text-uppercase">Status</h5>
          </div>
          <div class="d-none d-sm-block col-md-3"></div>
        </div>
        <!-- end display headers -->

        <!-- Remortgage Client Questionnaire - about your remortgage -->
        <display-section
          :settings="aboutYourRemortgage"
          :values="v_remortgage"
          :formSectionInfo="formSectionInfo.aboutYourRemortgage"
        />
        <!-- /end: Remortgage Client Questionnaire - about your remortgage -->

        <!-- Remortgage Client Questionnaire - transfer of equity -->
        <display-section
          :settings="transferOfEquity"
          :values="[...s_additionalToePersons, ...s_removedToePersons]"
          :formSectionInfo="formSectionInfo.transferOfEquity"
        />
        <!-- /end: Remortgage Client Questionnaire - transfer of equity -->
      </div>
      <!-- /end: display document list section -->

      <div class="col-md-12 pt-2">
        <button class="btn btn-info border-info float-right" @click="navigateToCaseView">Return to Case</button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
