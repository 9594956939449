import { BANK_STATEMENT } from '@/values/fileSlugs';

export default {
  currentLenderBankStatementDocuments: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: BANK_STATEMENT, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'currentLenderBankStatementDocuments',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText:
        'Please supply copies of all relevant bank statements, dating back 6 months, for our proof of funds checks:',
    },
  },
};
