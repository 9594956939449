<script>
import { mapState } from 'vuex';
import filterDocuments from '@/functions/document/filterDocuments';
import ModalFileUploader from '@/components/fileUploader/ModalFileUploader';
import filter from 'lodash/filter';

export default {
  name: 'DisplaySection',
  components: { ModalFileUploader },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    values: {
      required: true,
    },
    formSectionInfo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showGroupHeader: false,
    };
  },
  computed: {
    ...mapState({
      documents: (state) => state.documents.documents,
    }),
    getCertificatesCustomFunctionDescription() {
      return typeof this.values.planningPermissionNotRequiredDescription !== 'undefined' &&
        this.values.planningPermissionNotRequiredDescription !== null
        ? this.values.planningPermissionNotRequiredDescription
        : false;
    },
  },
  methods: {
    /**
     * Has one or more documents been upload for this section? Filter by documentSlug e.g. "solar_panels_long_lease_documents"
     * Take into account any errors for documents in this section so UI is not updated incorrectly.
     *
     * @param {*} documentSetting
     * @return {boolean} Has at least one document been uploaded successfully?
     */
    getDocumentStatus(documentSetting) {
      this.uploadedDocuments = filterDocuments(this.documents, documentSetting);
      let failedUploadedDocuments = filter(this.uploadedDocuments, (doc) => doc.error != null);

      let documentCount = Object.keys(this.uploadedDocuments).length;
      let oneSuccessfullDocumentUploaded =
        (documentCount !== 0 && failedUploadedDocuments.length == 0) || documentCount > failedUploadedDocuments.length;

      return oneSuccessfullDocumentUploaded;
    },
    isItDocumentRequired(setting, values) {
      this.showDescription = false;

      let isItRequired = false;
      if (typeof setting.question.value !== 'undefined' && typeof values[setting.question.value] !== 'undefined') {
        isItRequired = setting.question.answers.includes(values[setting.question.value]);
      } else if (
        typeof setting.question.value !== 'undefined' &&
        setting.question.value === 'certificatesCustomFunction'
      ) {
        isItRequired = this.certificatesCustomFunction();
      } else if (
        typeof setting.question.value !== 'undefined' &&
        setting.question.value === 'transferOfEquityCustomFunction'
      ) {
        isItRequired = this.transferOfEquityCustomFunction();
      }

      if (!this.showGroupHeader && isItRequired) {
        this.showGroupHeader = true;
      }

      return isItRequired;
    },
    certificatesCustomFunction() {
      return (
        (typeof this.values.replacementWindowInstallation !== 'undefined' && // eslint-disable-next-line
          this.values.replacementWindowInstallation) ||
        (typeof this.values.buildingWorks !== 'undefined' && this.values.buildingWorks) ||
        (typeof this.values.changeOfUse !== 'undefined' && this.values.changeOfUse) ||
        (typeof this.values.conservatoryAddition !== 'undefined' && this.values.conservatoryAddition)
      );
    },
    transferOfEquityCustomFunction() {
      const courtOrderEnclosed = this.values.filter((value) => {
        return value.toeTransferByCourtOrderDocuments === 'ETF_ENCLOSED';
      });

      return courtOrderEnclosed.length > 0;
    },
    toggleFilesUploader(setting) {
      this.selectedDocumentSettings = setting;
    },
  },
};
</script>

<template>
  <div>
    <!-- Group name -->
    <div v-if="showGroupHeader" class="row">
      <div class="col-md-12 border-bottom-1 pt-3">
        <h5 class="d-flex justify-content-center justify-content-md-start text-uppercase">{{ formSectionInfo }}</h5>
      </div>
    </div>
    <!-- End group name -->

    <template v-for="(setting, index) in settings">
      <div
        class="row d-flex align-items-center border-top p-2"
        :key="index"
        v-if="isItDocumentRequired(setting, values)"
      >
        <!-- Display Name -->
        <div class="col-sm-12 col-md-6">
          <h5 class="d-flex justify-content-center justify-content-md-start text-default letter-icon-title">
            {{ setting.question.documentText }}
          </h5>
          <div
            v-if="getCertificatesCustomFunctionDescription && setting.question.value === 'certificatesCustomFunction'"
          >
            <span class="d-flex justify-content-center justify-content-md-start text-uppercase font-weight-light">
              {{ getCertificatesCustomFunctionDescription }}
            </span>
          </div>
        </div>
        <!-- End Display Name -->

        <!-- Display badge -->
        <div class="col-sm-12 col-md-3">
          <div class="d-flex justify-content-center justify-content-md-start">
            <span
              v-if="getDocumentStatus(setting)"
              class="badge-success badge badge-pill border-3 mr-1 mb-1 text-uppercase"
            >
              Document Uploaded
            </span>

            <span
              v-else-if="setting.question.value === 'certificatesCustomFunction'"
              class="badge-info badge badge-pill border-3 mr-1 mb-1 text-uppercase"
            >
              Document Upload Is Optional
            </span>

            <span v-else class="badge-danger badge badge-pill border-3 mr-1 mb-1 text-uppercase">
              Document Required
            </span>
          </div>
        </div>
        <!-- End display badge -->

        <!-- Display button -->
        <div class="col-sm-12 col-md-3">
          <div class="d-flex justify-content-center justify-content-md-end">
            <modal-file-uploader :requiredDocument="setting"></modal-file-uploader>
          </div>
        </div>
        <!-- End display button -->
      </div>
    </template>
  </div>
</template>
