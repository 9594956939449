//import sortDocumentTypes from '@sort-group/sort-document-types';

import { AGREE_TO_SALE_CONTRACT_DOCUMENTS } from '@/values/fileSlugs';

export default {
  agreeToSaleContractDocuments: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: AGREE_TO_SALE_CONTRACT_DOCUMENTS, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'agreeToSaleContractDocuments',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Evidence that the property will be vacant on completion',
    },
  },
};
