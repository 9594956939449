<script>
import DisplaySection from '@/components/supportingDocumentsList/DisplaySection';
import alterations from '@/functions/supportingDocuments/alterations';
import boundaries from '@/functions/supportingDocuments/boundaries';
import consents from '@/functions/supportingDocuments/consents';
import enfranchisement from '@/functions/supportingDocuments/enfranchisement';
import environmentalMatters from '@/functions/supportingDocuments/environmentalMatters';
import guarantees from '@/functions/supportingDocuments/guarantees';
import rights from '@/functions/supportingDocuments/rights';
import leaseholdAlterations from '@/functions/supportingDocuments/leaseholdAlterations';
import leaseholdNotices from '@/functions/supportingDocuments/leaseholdNotices';
import occupiers from '@/functions/supportingDocuments/occupiers';
import relevantDocument from '@/functions/supportingDocuments/relevantDocument';
import leaseholdSafety from '@/functions/supportingDocuments/leaseholdSafety';
import services from '@/functions/supportingDocuments/services';
import { mapState } from 'vuex';

const formSectionInfo = {
  alterations: 'TA6 - Alterations, planning and building control',
  boundaries: 'TA6 - Boundaries',
  environmentalMatters: 'TA6 - Environmental matters',
  guarantees: 'TA6 - Guarantees and warranties',
  occupiers: 'TA6 - Occupiers',
  rights: 'TA6 - Rights and informal arrangements',
  services: 'TA6 - Services',
  consents: 'TA7 - Consents',
  enfranchisement: 'TA7 - Enfranchisement',
  leaseholdAlterations: 'TA7 - Leasehold alterations',
  leaseholdNotices: 'TA7 - Notices',
  relevantDocument: 'TA7 - Relevant documents',
  leaseholdSafety: 'TA7 - Building safety, cladding and the leaseholder deed of certificate',
};

export default {
  name: 'DisplayList',
  components: { DisplaySection },
  props: ['entityType', 'entityId'],
  data() {
    return {
      alterations,
      boundaries,
      consents,
      enfranchisement,
      environmentalMatters,
      guarantees,
      rights,
      leaseholdAlterations,
      leaseholdNotices,
      occupiers,
      relevantDocument,
      leaseholdSafety,
      services,
      formSectionInfo,
    };
  },
  computed: {
    ...mapState({
      s_alterations: (state) => state.building.alterations,
      s_boundary: (state) => state.building.boundary,
      s_consents: (state) => state.building.consents,
      s_enfranchisement: (state) => state.building.enfranchisement,
      s_environmentalMatters: (state) => state.building.environmentalMatters,
      s_guarantees: (state) => state.building.guarantees,
      s_rights: (state) => state.building.rights,
      s_leaseholdAlterations: (state) => state.building.leaseholdAlterations,
      s_leaseholdNotices: (state) => state.building.leaseholdNotices,
      s_occupiers: (state) => state.building.occupiers,
      s_relevantDocuments: (state) => state.building.relevantDocuments,
      s_leaseholdSafety: (state) => state.building.leaseholdSafety,
      s_services: (state) => state.building.services,
    }),
    v_alterations: {
      get() {
        return this.s_alterations;
      },
    },
    v_boundary: {
      get() {
        return this.s_boundary;
      },
    },
    v_consents: {
      get() {
        return this.s_consents;
      },
    },
    v_enfranchisement: {
      get() {
        return this.s_enfranchisement;
      },
    },
    v_environmentalMatters: {
      get() {
        return this.s_environmentalMatters;
      },
    },
    v_guarantees: {
      get() {
        return this.s_guarantees;
      },
    },
    v_rights: {
      get() {
        return this.s_rights;
      },
    },
    v_leaseholdAlterations: {
      get() {
        return this.s_leaseholdAlterations;
      },
    },
    v_leaseholdNotices: {
      get() {
        return this.s_leaseholdNotices;
      },
    },
    v_leaseholdSafety: {
      get() {
        return this.s_leaseholdSafety;
      },
    },
    v_occupiers: {
      get() {
        return this.s_occupiers;
      },
    },
    v_relevantDocuments: {
      get() {
        return this.s_relevantDocuments;
      },
    },
    v_services: {
      get() {
        return this.s_services;
      },
    },
  },
  methods: {
    navigateToCaseView() {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <h2>Check and Upload Supporting Documents</h2>
          </div>

          <div class="col-md-3">
            <button class="btn btn-info border-info float-right" @click="navigateToCaseView">Return to Case</button>
          </div>
        </div>
      </div>

      <div class="col-md-12 my-3">
        <h5>
          Based on your answers to the client questionnaire and information forms, these are the supporting documents
          you have indicated are applicable and available to attach to your case. Making these documents available at
          this stage will ensure all parties have the information they need to progress as swiftly as possible.
          <b
            >This list serves only as a reminder of your answers and to help collate the necessary documentation and
            will not prevent your case from progressing if you are unable to provide certain documents.</b
          >
        </h5>
      </div>

      <!-- display document list section -->
      <div class="col-md-12">
        <!-- display headers -->
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h5 class="d-flex justify-content-center justify-content-md-start text-uppercase">
              Required Document Name:
            </h5>
          </div>
          <div class="d-none d-md-block col-md-3">
            <h5 class="text-uppercase">Status</h5>
          </div>
          <div class="d-none d-sm-block col-md-3"></div>
        </div>
        <!-- end display headers -->

        <!-- TA6 related documents-->
        <display-section
          :settings="alterations"
          :values="v_alterations"
          :formSectionInfo="formSectionInfo.alterations"
        />
        <display-section :settings="boundaries" :values="v_boundary" :formSectionInfo="formSectionInfo.boundaries" />
        <display-section
          :settings="environmentalMatters"
          :values="v_environmentalMatters"
          :formSectionInfo="formSectionInfo.environmentalMatters"
        />
        <display-section :settings="guarantees" :values="v_guarantees" :formSectionInfo="formSectionInfo.guarantees" />
        <display-section :settings="occupiers" :values="v_occupiers" :formSectionInfo="formSectionInfo.occupiers" />
        <display-section :settings="rights" :values="v_rights" :formSectionInfo="formSectionInfo.rights" />
        <display-section :settings="services" :values="v_services" :formSectionInfo="formSectionInfo.services" />
        <!-- End TA6 related documents  -->

        <!-- TA7 related documents-->
        <display-section :settings="consents" :values="v_consents" :formSectionInfo="formSectionInfo.consents" />
        <display-section
          :settings="enfranchisement"
          :values="v_enfranchisement"
          :formSectionInfo="formSectionInfo.enfranchisement"
        />
        <display-section
          :settings="leaseholdAlterations"
          :values="v_leaseholdAlterations"
          :formSectionInfo="formSectionInfo.leaseholdAlterations"
        />
        <display-section
          :settings="leaseholdNotices"
          :values="v_leaseholdNotices"
          :formSectionInfo="formSectionInfo.leaseholdNotices"
        />
        <display-section
          :settings="relevantDocument"
          :values="v_relevantDocuments"
          :formSectionInfo="formSectionInfo.relevantDocument"
        />
        <display-section
          :settings="leaseholdSafety"
          :values="v_leaseholdSafety"
          :formSectionInfo="formSectionInfo.leaseholdSafety"
        />
        <!-- End TA7 related documents  -->
      </div>
      <!-- /table section end -->

      <div class="col-md-12 pt-2">
        <button class="btn btn-info border-info float-right" @click="navigateToCaseView">Return to Case</button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
