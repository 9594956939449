import sortDocumentTypes from '@sort-group/sort-document-types';

import {
  CERTIFICATES,
  SOLAR_PANELS_LONG_LEASE_DOCUMENTS,
  LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS,
  PRESERVATION_ORDER_RELEVANT_DOCUMENTS,
} from '@/values/fileSlugs';

export default {
  certificates: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: true, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: CERTIFICATES, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    // Example: BUILDING_REGULATIONS: sortDocumentTypes.BUILDING_REGULATIONS,
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'certificatesCustomFunction', // This question require custom logic to find out if document is required
      answers: [],
      mainText: false,
      documentText:
        'Please supply copies of the planning permissions, Building Regulations approvals and Completion Certificates',
    },
  },
  solarPanelsLongLeaseDocuments: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: true,
    documentSlug: SOLAR_PANELS_LONG_LEASE_DOCUMENTS,
    documentTypes: {
      HETAS: sortDocumentTypes.HETAS,
    },
    matchByDocumentTypes: false,
    question: {
      value: 'solarPanelsLongLeaseDocuments',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText:
        'Please supply copies of the relevant documents e.g. copies of electricity bills for feed in tariffs',
    },
  },
  listedBuildingConservationAreaDocuments: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: true,
    documentSlug: LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS,
    documentTypes: {
      BUILDING_REGULATIONS: sortDocumentTypes.BUILDING_REGULATIONS,
      CAVITY_WALL_INSULATION: sortDocumentTypes.CAVITY_WALL_INSULATION,
      CORGI: sortDocumentTypes.CORGI,
    },
    matchByDocumentTypes: false,
    question: {
      value: 'listedBuildingConservationAreaDocuments',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Conservation area relevant documents',
    },
  },
  preservationOrderRelevantDocuments: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: true,
    documentSlug: PRESERVATION_ORDER_RELEVANT_DOCUMENTS,
    documentTypes: {
      CORGI: sortDocumentTypes.CORGI,
    },
    matchByDocumentTypes: false,
    question: {
      value: 'preservationOrderRelevantDocuments',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'The trees on the property subject to a Tree Preservation Order relevant documents',
    },
  },
};
