<script>
import FormWrapper from '@/components/FormWrapper';
import Loader from '@/components/Loader';
import DisplayListSale from '@/components/supportingDocumentsList/Sale/DisplayList';
import DisplayListRemortgage from '@/components/supportingDocumentsList/Remortgage/DisplayList';
import { mapState } from 'vuex';

export default {
  name: 'supportingDocuments',
  components: { FormWrapper, Loader, DisplayListSale, DisplayListRemortgage },
  props: ['entityType', 'entityId'],
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
};
</script>

<template>
  <form-wrapper :entityType="entityType" :entityId="entityId" class="mb-5">
    <div class="home" slot="form-content">
      <loader v-if="loading">Loading Optional Documents...</loader>
      <display-list-sale
        v-else-if="this.entityType === 'sale'"
        :entity-type="this.entityType"
        :entity-id="this.entityId"
      />
      <display-list-remortgage
        v-else-if="this.entityType === 'remortgage'"
        :entity-type="this.entityType"
        :entity-id="this.entityId"
      />
    </div>
  </form-wrapper>
</template>
<style lang="scss" scoped></style>
