//import sortDocumentTypes from '@sort-group/sort-document-types';

import {
  NEW_HOME_WARRANTY_COPY,
  DAMP_PROOFING_COPY,
  TIMBER_TREATMENT_COPY,
  WINDOWS_COPY,
  ELECTRICAL_WORK_COPY,
  ROOFING_COPY,
  CENTRAL_HEATING_COPY,
  UNDERPINNING_COPY,
  GUARANTEES_OTHER,
} from '@/values/fileSlugs';

export default {
  newHomeWarrantyCopy: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: NEW_HOME_WARRANTY_COPY, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'newHomeWarrantyCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'New home warranty (e.g. NHBC or similar)',
    },
  },
  dampProofingCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: DAMP_PROOFING_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'dampProofingCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of damp proofing',
    },
  },
  timberTreatmentCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: TIMBER_TREATMENT_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'timberTreatmentCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of timber treatment',
    },
  },
  windowsCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: WINDOWS_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'windowsCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of windows, rood lights, roof windows or glazed doors',
    },
  },
  electricalWorkCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: ELECTRICAL_WORK_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'electricalWorkCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of electrical work',
    },
  },
  roofingCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: ROOFING_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'roofingCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of roofing',
    },
  },
  centralHeatingCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: CENTRAL_HEATING_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'centralHeatingCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of central heating',
    },
  },
  underpinningCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: UNDERPINNING_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'underpinningCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of underpinning',
    },
  },
  other: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: GUARANTEES_OTHER,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'otherCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please provide a copy of other guaranties',
    },
  },
};
