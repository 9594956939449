//import sortDocumentTypes from '@sort-group/sort-document-types';

import {
  FLOOD_RISK_REPORT_COPY,
  RADON_TEST_REPORT,
  EPC_COPY,
  INSTALLATIONS_GREEN_DEAL_FINANCED,
  JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY,
} from '@/values/fileSlugs';

export default {
  floodRiskReportCopy: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: FLOOD_RISK_REPORT_COPY, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'floodRiskReportCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: 'Has a Flood Risk Report been prepared?',
      documentText: 'Flood Risk Report copy',
    },
  },
  radonTestReport: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: RADON_TEST_REPORT,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'radonTestReport',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: 'Has a Radon test been carried out on the property?',
      documentText: 'Please supply a copy of the Radon test',
    },
  },
  epcCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: EPC_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'epcCopy',
      answers: ['ETFS_ENCLOSED', 'ETFS_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of the EPC for the property',
    },
  },
  installationsGreenDealFinanced: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: INSTALLATIONS_GREEN_DEAL_FINANCED,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'installationsGreenDealFinanced',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of your last electricity bill',
    },
  },
  japaneseKnotweedManagementPlanCopy: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'japaneseKnotweedManagementPlanCopy',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: 'Please state whether there is a Japanese knotweed management plan in place',
      documentText: 'Please supply a copy of  japanese knotweed management plan',
    },
  },
};
