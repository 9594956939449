//import sortDocumentTypes from '@sort-group/sort-document-types';

import {
  ELECTRICS_TESTED_CERTIFICATE,
  ELECTRICAL_SAFETY_CERTIFICATE,
  COMPLIANCE_CERTIFICATE,
  COMPLETION_CERTIFICATE,
  CENTRAL_HEATING_BEFORE_SPECIFIED_DATE,
  HEATING_SYSTEM_SERVICED_INSPECTION_REPORT,
  SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS,
} from '@/values/fileSlugs';

export default {
  electricsTestedCertificate: {
    text: false, // Label to display for inline upload section, will replace default text
    showAcceptedDocumentList: false, // Show list of accepted documents on inline section
    showAllDocumentTypes: false, // We can show full list of document types or reduced list listed bellow as documentTypes
    documentSlug: ELECTRICS_TESTED_CERTIFICATE, // unique slug which we pass with file as metadata to match uploaded file with question
    documentTypes: {}, // here we can overwrite default types to this list, showAllDocumentTypes must be set to true to display this list
    matchByDocumentTypes: false, // By default we match question with file by slug, we can also add extra matching
    // by documentTypes listed above, showAllDocumentTypes could be set to false/true and matching will still work
    question: {
      value: 'electricsTestedCertificate',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please provide a copy of the electrical installation test certificate',
    },
  },
  electricalSafetyCertificate: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: ELECTRICAL_SAFETY_CERTIFICATE,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'electricalSafetyCertificate',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please provide a copy of the signed BS7671 Electrical Safety Certificate',
    },
  },
  complianceCertificate: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: COMPLIANCE_CERTIFICATE,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'complianceCertificate',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please provide the installer’s Building Regulations Compliance Certificate',
    },
  },
  completionCertificate: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: COMPLETION_CERTIFICATE,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'completionCertificate',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please provide the Building Control Completion Certificate',
    },
  },
  centralHeatingBeforeSpecifiedDate: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: CENTRAL_HEATING_BEFORE_SPECIFIED_DATE,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'centralHeatingBeforeSpecifiedDate',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText:
        'Please supply a copy of the ‘completion certificate’ (e.g. CORGI or Gas Safe Register) or the exceptional circumstances form',
    },
  },
  heatingSystemServicedInspectionReport: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: HEATING_SYSTEM_SERVICED_INSPECTION_REPORT,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'heatingSystemServicedInspectionReport',
      answers: ['ETFNA_ENCLOSED', 'ETFNA_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a copy of the inspection report',
    },
  },
  sewerageSystemOutsideOfPropertyDocuments: {
    text: false,
    showAcceptedDocumentList: false,
    showAllDocumentTypes: false,
    documentSlug: SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS,
    documentTypes: {},
    matchByDocumentTypes: false,
    question: {
      value: 'sewerageSystemOutsideOfPropertyDocuments',
      answers: ['ETF_ENCLOSED', 'ETF_TO_FOLLOW'],
      mainText: false,
      documentText: 'Please supply a plan showing the location of the system and how access is obtained',
    },
  },
};
