<script>
import Modal from '@/components/Modal';
import UploadedFilesList from '@/components/documentManager/UploadedFilesList';
import sortDocumentTypes from '@sort-group/sort-document-types';
import filterDocuments from '@/functions/document/filterDocuments';
import { mapState } from 'vuex';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import filter from 'lodash/filter';

export default {
  name: 'InlineFileUploader',
  components: { Modal, UploadedFilesList },
  computed: {
    ...mapState({
      entityId: (state) => {
        if (state.sale.saleId !== null) {
          return state.sale.saleId;
        } else if (state.purchase.purchaseId !== null) {
          return state.purchase.purchaseId;
        } else if (state.remortgage.remortgageId !== null) {
          return state.remortgage.remortgageId;
        } else {
          return null;
        }
      },
      entityType: (state) => {
        if (state.sale.saleId !== null) {
          return 'sale';
        } else if (state.purchase.purchaseId !== null) {
          return 'purchase';
        } else if (state.remortgage.remortgageId !== null) {
          return 'remortgage';
        } else {
          return null;
        }
      },
      entity: (state) => {
        if (state.sale.saleId !== null) {
          return state.sale;
        } else if (state.purchase.purchaseId !== null) {
          return state.purchase;
        } else if (state.remortgage.remortgageId !== null) {
          return state.remortgage;
        } else {
          return null;
        }
      },
      documents: (state) => state.documents.documents,
    }),
    alreadyUploadedFilesQty() {
      let hasErrors = filter(this.uploadedDocuments, (doc) => doc.error != null);
      return Object.keys(this.uploadedDocuments).length - hasErrors.length;
    },
    listOfRelevantDocs() {
      if (this.uploadedDocuments.length !== 0) {
        return pickBy(this.sortDocumentTypes, (value, key) => {
          return map(this.uploadedDocuments, 'documentType').includes(key);
        });
      } else {
        return this.requiredDocument.documentTypes;
      }
    },
    showAcceptedDocumentList() {
      return this.requiredDocument.showAcceptedDocumentList;
    },
  },
  props: {
    requiredDocument: {
      type: Object,
      default: () => ({}),
      true: true,
    },
  },
  data() {
    return {
      showModal: false,
      uploadedDocuments: {},
      sortDocumentTypes,
    };
  },
  methods: {
    toggleShowModal() {
      this.showModal = !this.showModal;
    },
  },
  watch: {
    documents() {
      this.uploadedDocuments = filterDocuments(this.documents, this.requiredDocument);
    },
  },
};
</script>

<template>
  <div class="row">
    <div>
      <div v-if="alreadyUploadedFilesQty !== 0">
        <div class="status position-relative">
          <button type="button" class="btn btn-success btn-sm" @click="toggleShowModal">
            View files
            <span
              class="badge badge-success badge-pill position-absolute border border-light"
              style="right: -8pt; top: -8pt"
              >{{ this.alreadyUploadedFilesQty }}</span
            >
          </button>
        </div>
      </div>
      <div v-else>
        <div class="status position-relative">
          <button type="button" class="btn btn-info btn-sm" @click="toggleShowModal">Upload files</button>
          <span
            class="badge badge-secondary badge-pill position-absolute border border-light"
            style="right: -8pt; top: -8pt"
            >{{ this.alreadyUploadedFilesQty }}</span
          >
        </div>
      </div>
    </div>
    <modal
      v-if="this.showModal"
      :show-buttons="true"
      closeButtonText="Close"
      :fullModalWidth="true"
      @proceed="toggleShowModal"
    >
      <uploaded-files-list
        v-if="this.showModal"
        :entityId="entityId"
        :entityType="entityType"
        :entity="entity"
        :documentTypes="requiredDocument.documentTypes"
        :documentSlug="requiredDocument.documentSlug"
        :requiredDocument="requiredDocument"
      />
    </modal>
  </div>
</template>
